import { noop } from 'lodash';

import type { User } from '@/app/actions/auth/types';

import type { CreateSlice } from '@/zustand-store/store.types';

type VerificationState = {
  hasOtp: boolean;
  isLoginWithPhone: boolean;
  phone: string;
  subscriptionTitle: string;
  isRenew: boolean;
  isAcceptedPolicy: boolean;
  isAuthVerification: boolean;
  isSubscriptionVerification: boolean;
  userAccessToken: string | null;
  userCredentials: User | null;
  isInitialCheckDone: boolean;
  authCallbackOnSuccess: (...args: any[]) => void;
  subscriptionCallbackOnSuccess: (...args: any[]) => void;
};

type VerificationMethods = {
  setUserAccessToken: (token: string | null) => void;
  setUserCredentials: (credentials: User | null) => void;
  loginWithPhone: () => void;
  loginWithUsername: () => void;
  setOtpStatus: () => void;
  setPhone: (phone: string) => void;
  acceptPolicy: (status: boolean) => void;
  authVerify: () => void;
  subscriptionVerify: () => void;
  onCloseVerificationModal: () => void;
  onShallowCloseVerificationModal: () => void;
  setSubscriptionTitle: (title: string) => void;
  setRenew: (isRenew: boolean) => void;
  setAuthVerificationOnSuccess: (callback: (...args: any[]) => void) => void;
  setSubscriptionVerificationOnSuccess: (callback: (...args: any[]) => void) => void;
  setIsInitialCheckDone: (isInitialCheckDone: boolean) => void;
};

export type VerificationStore = {
  verificationStore: VerificationState & VerificationMethods;
};

const initialState: Omit<
  VerificationState,
  'userAccessToken' | 'userCredentials' | 'isInitialCheckDone'
> = {
  hasOtp: false,
  isLoginWithPhone: true,
  isAcceptedPolicy: false,
  isAuthVerification: false,
  isSubscriptionVerification: false,
  phone: '',
  subscriptionTitle: '',
  isRenew: true,
  authCallbackOnSuccess: noop,
  subscriptionCallbackOnSuccess: noop,
};

export const createVerificationSlice: CreateSlice<VerificationStore> = set => ({
  verificationStore: {
    ...initialState,
    userAccessToken: null,
    userCredentials: null,
    isInitialCheckDone: false,

    setUserAccessToken: token =>
      set(state => ({ verificationStore: { ...state.verificationStore, userAccessToken: token } })),
    setUserCredentials: user =>
      set(state => ({ verificationStore: { ...state.verificationStore, userCredentials: user } })),
    loginWithPhone: () =>
      set(state => ({ verificationStore: { ...state.verificationStore, isLoginWithPhone: true } })),
    loginWithUsername: () =>
      set(state => ({
        verificationStore: { ...state.verificationStore, isLoginWithPhone: false },
      })),
    setSubscriptionTitle: title =>
      set(state => ({
        verificationStore: { ...state.verificationStore, subscriptionTitle: title },
      })),
    setOtpStatus: () =>
      set(state => ({
        verificationStore: { ...state.verificationStore, hasOtp: !state.verificationStore.hasOtp },
      })),
    setPhone: phone => set(state => ({ verificationStore: { ...state.verificationStore, phone } })),
    acceptPolicy: status =>
      set(state => ({
        verificationStore: { ...state.verificationStore, isAcceptedPolicy: status },
      })),
    authVerify: () =>
      set(state => ({
        verificationStore: {
          ...state.verificationStore,
          isAuthVerification: true,
          isSubscriptionVerification: false,
        },
      })),
    subscriptionVerify: () =>
      set(state => ({
        verificationStore: {
          ...state.verificationStore,
          isAuthVerification: false,
          isSubscriptionVerification: true,
        },
      })),
    onCloseVerificationModal: () =>
      set(state => ({
        verificationStore: { ...state.verificationStore, ...initialState },
      })),
    onShallowCloseVerificationModal: () =>
      set(state => ({
        verificationStore: {
          ...state.verificationStore,
          isAuthVerification: false,
          isSubscriptionVerification: false,
        },
      })),

    setAuthVerificationOnSuccess: authCallbackOnSuccess =>
      set(state => ({
        verificationStore: { ...state.verificationStore, authCallbackOnSuccess },
      })),
    setSubscriptionVerificationOnSuccess: subscriptionCallbackOnSuccess =>
      set(state => ({
        verificationStore: { ...state.verificationStore, subscriptionCallbackOnSuccess },
      })),

    setRenew: isRenew =>
      set(state => ({
        verificationStore: {
          ...state.verificationStore,
          isRenew,
        },
      })),
    setIsInitialCheckDone: isInitialCheckDone =>
      set(state => ({
        verificationStore: { ...state.verificationStore, isInitialCheckDone },
      })),
  },
});
