'use client';

import { useMemo, useState } from 'react';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslations } from 'next-intl';
import * as Yup from 'yup';

import { loginWithPhone } from '@/app/actions/auth';
import UserPhoneSVG from '@/icons/UserPhoneSVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import type { ErrorResponse } from '@/api/types/common.types';

import { HTTP_CODE_SUCCESS } from '@/constants/http-code.constants';

import { TextButton } from '@/components/Buttons';
import { SolidButton } from '@/components/Buttons/SolidButton';
import { Form, FormAlertType } from '@/components/Form';

import styles from '@/contexts/VerificationContext/VerificationModal/AuthVerification/AuthVerification.module.scss';
import { SocialLoginPanel } from '@/contexts/VerificationContext/VerificationModal/AuthVerification/SocialLoginPanel';

// =================================================================

interface PhoneFormProps {
  title: string;
}

type PhoneFormType = {
  phone: string;
  privacyAccepted: boolean;
};

// =================================================================

export const PhoneForm = (props: PhoneFormProps) => {
  const { title } = props;
  const t = useTranslations();

  const [status, setStatus] = useState<FormAlertType['status'] | null>(null);

  const { loginWithUsername, setPhone, acceptPolicy, setOtpStatus, phone, isAcceptedPolicy } =
    useVerification();

  const schema = useMemo(
    () =>
      Yup.object({
        phone: Yup.string()
          .trim()
          .required(t('formWarningMassage.requiredField'))
          .matches(/[0-9 ]{3}[0-9 ]{4}[0-9 ]{3}[0-9]{2}/, t('formWarningMassage.incorrectNumber')),
        privacyAccepted: Yup.boolean().required(),
      }),
    [t],
  );

  const methods = useForm<PhoneFormType>({
    defaultValues: {
      phone,
      privacyAccepted: Boolean(isAcceptedPolicy),
    },
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const onSubmit: SubmitHandler<PhoneFormType> = async data => {
    const { phone, privacyAccepted } = data;
    const normalizedPhone = phone.replace(/\s/g, '');

    const response = await loginWithPhone(normalizedPhone);

    if (response.code === HTTP_CODE_SUCCESS) {
      setPhone(phone);
      setOtpStatus();
      acceptPolicy(privacyAccepted);
    } else {
      const { message } = response as ErrorResponse;
      setStatus({
        type: 'error',
        message,
      });
    }
  };

  const redirectPrivacyAccepted = () => {
    const phoneValue = methods.getValues('phone');
    setPhone(phoneValue);

    acceptPolicy(methods.getValues('privacyAccepted'));
  };

  return (
    <FormProvider {...methods}>
      <Form
        isSubmitting={methods.formState.isSubmitting}
        onSubmit={methods.handleSubmit(onSubmit)}
        className={styles.authForm}
      >
        <Form.Body className={styles.authFormBody}>
          {status && <Form.Alert status={status} className={styles.alert} />}
          <p className={styles.description}>{title}</p>
          <Form.NumberInput
            isPhoneInput
            name="phone"
            icon={UserPhoneSVG}
            placeholder={'__ ___ __ __'}
            format="## ### ## ##"
            mask="_"
            errorClassName={styles.errorMessage}
            className={styles.inputNumber}
            autoFocus
          />
          <Form.Checkbox
            name="privacyAccepted"
            label={
              <div className={styles.checkbox}>
                {t('verification.accept')}
                <TextButton
                  as="link"
                  href={'/privacy-policy?fromSignInModal=true'}
                  onClick={redirectPrivacyAccepted}
                  className={styles.link}
                >
                  {t('verification.termsOfUse')}
                </TextButton>
              </div>
            }
          />
        </Form.Body>
        <div className={styles.lineRow} />
        <Form.Footer className={styles.formFooter}>
          <div className={styles.phoneLoginButtonGroup}>
            <SolidButton
              as="button"
              type="submit"
              disabled={!methods.watch('privacyAccepted') || methods.formState.isSubmitting}
              className={styles.button}
            >
              {t('verification.sendCode')}
            </SolidButton>

            <TextButton
              as="button"
              type="button"
              className={styles.button}
              onClick={loginWithUsername}
            >
              {t('verification.signInUsingYourLogin')}
            </TextButton>
          </div>
          <SocialLoginPanel />
        </Form.Footer>
      </Form>
    </FormProvider>
  );
};
