'use client';

import { ElementType, Fragment, useState } from 'react';

import { useTranslations } from 'next-intl';

import type { SessionList } from '@/app/actions/auth/types';
import PCSVG from '@/icons/DevicePCSVG';
import TrashbinSVG from '@/icons/TrashbinSVG';

import { UserApi } from '@/api/domains/user-api';

import { HTTP_CODE_SUCCESS } from '@/constants/http-code.constants';

import styles from '@/components/SessionCard/SessionCard.module.scss';
import { Spinner } from '@/components/Spinner';
import { TruncatedText } from '@/components/TruncatedText';

// =================================================================

type SessionCardProps = {
  as?: ElementType;
  session: SessionList;
  onSessionDelete?: () => void;
};

// =================================================================

export const SessionCard = (props: SessionCardProps) => {
  const { as: Element = 'div', session, onSessionDelete } = props;

  const { deviceBrand, deviceModel, loginDate, isCurrent, sessionId } = session;

  const title = `${deviceBrand}${deviceModel ? `, ${deviceModel}` : ''}`;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const deleteSession = async (sessionId: string) => {
    setIsLoading(true);
    try {
      const response = await UserApi.deleteSession({ sessionId });

      if (response.code === HTTP_CODE_SUCCESS) {
        onSessionDelete?.();
      }
    } catch (error) {
      if (!error.resonse) {
        throw error;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const t = useTranslations('page.userProfile');

  return (
    <Element className={styles.sessionCard}>
      <div className={styles.iconWrapper}>
        <PCSVG width={30} height={30} />
      </div>
      <div className={styles.sessionDetails}>
        <div className={styles.detailsText}>
          <TruncatedText as="p" lines={1}>
            {title}
          </TruncatedText>
          <TruncatedText as="p" lines={1} className={styles.sessionDate}>
            {loginDate}
          </TruncatedText>
        </div>
        {isCurrent ? (
          <span className={styles.currentSession}>{t('currentSession')}</span>
        ) : (
          <button
            disabled={isLoading}
            className={styles.deleteButton}
            onClick={() => deleteSession(sessionId)}
          >
            {isLoading ? (
              <Spinner className={styles.spinner} />
            ) : (
              <Fragment>
                <TrashbinSVG width={18} height={23} className={styles.trashbinIcon} />
                {t('delete')}
              </Fragment>
            )}
          </button>
        )}
      </div>
    </Element>
  );
};
