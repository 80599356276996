'use client';

import { useEffect, useState, Fragment, useMemo } from 'react';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import * as yup from 'yup';

import { loginWithPhone, confirmOtp } from '@/app/actions/auth';
import type { SessionsReachedResponse } from '@/app/actions/auth/types';
import type { SessionList } from '@/app/actions/auth/types';
import UserPasswordSVG from '@/icons/UserPasswordSVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import type { ErrorResponse } from '@/api/types/common.types';

import { normalizePhone } from '@/helpers/transformations.helpers';

import {
  HTTP_CODE_REACHED_SESSIONS_LIMIT,
  HTTP_CODE_SUCCESS,
} from '@/constants/http-code.constants';

import { TextButton } from '@/components/Buttons';
import { SolidButton } from '@/components/Buttons/SolidButton';
import { Form, FormAlertType } from '@/components/Form';
import { SessionsModal } from '@/components/SessionsModal';

import styles from '@/contexts/VerificationContext/VerificationModal/AuthVerification/AuthVerification.module.scss';

// =================================================================

type SmsFormType = {
  code: string;
};

// =================================================================

const MAX_LENGTH = 6;

// =================================================================

export const SmsForm = () => {
  const t = useTranslations();

  const { phone, setOtpStatus, setAccessToken, setUserCredentials, authCallbackOnSuccess } =
    useVerification();

  const [timer, setTimer] = useState(59);
  const [status, setStatus] = useState<FormAlertType['status'] | null>(null);
  const [sessions, setSessions] = useState<SessionList[]>([]);
  const [code, setCode] = useState('');

  const schema = useMemo(
    () =>
      yup
        .object({
          code: yup
            .string()
            .trim()
            .matches(/[0-9]{5}/, t('formWarningMassage.invalidCode'))
            .required(t('formWarningMassage.requiredField'))
            .max(MAX_LENGTH, t('formWarningMassage.maxLengthExceeded', { maxLength: MAX_LENGTH })),
        })
        .required(),
    [t],
  );

  const methods = useForm<SmsFormType>({
    defaultValues: {
      code: '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer) {
        setTimer(timer => timer - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [timer]);

  const onSubmit: SubmitHandler<SmsFormType> = async data => {
    const { code } = data;
    setCode(code);

    const response = await confirmOtp({ phone, code });
    if (response.code === HTTP_CODE_SUCCESS) {
      const { cineramaToken, me } = response.data;
      setAccessToken(cineramaToken);
      setUserCredentials(me);

      authCallbackOnSuccess();
    } else if (response.code === HTTP_CODE_REACHED_SESSIONS_LIMIT) {
      const { sessionList } = response.data as unknown as SessionsReachedResponse;
      setSessions(sessionList);
      setStatus({
        type: 'error',
        message: response.message,
      });
    } else {
      const { message } = response as ErrorResponse;
      setStatus({ type: 'error', message });
    }
  };

  const resend = async () => {
    setTimer(59);
    setStatus(null);
    methods.reset();
    await loginWithPhone(phone);
  };

  const handleDeleteSession = async () => {
    const response = await confirmOtp({ phone, code });
    if (response.code === HTTP_CODE_SUCCESS) {
      const { cineramaToken, me } = response.data;
      setAccessToken(cineramaToken);
      setUserCredentials(me);
      authCallbackOnSuccess();
    }
  };

  const transformedTime = ('00' + timer).slice(-2);

  return (
    <Fragment>
      <FormProvider {...methods}>
        <Form
          isSubmitting={methods.formState.isSubmitting}
          onSubmit={methods.handleSubmit(onSubmit)}
          className={styles.authForm}
        >
          <Form.Body className={styles.authFormBody}>
            {status && <Form.Alert status={status} className={styles.alert} />}
            <p className={styles.description}>
              {t('verification.sendToPhoneMessage', {
                phone: normalizePhone(phone),
              })}
            </p>
            <Form.Input
              name="code"
              label={t('verification.placeholderCode')}
              autoFocus
              icon={UserPasswordSVG}
              errorClassName={styles.errorMessage}
              type="number"
            />
          </Form.Body>
          <Form.Footer className={styles.formFooter}>
            <TextButton
              as="button"
              type="button"
              className={clsx(styles.button, styles.resetCode)}
              disabled={Boolean(timer)}
              onClick={resend}
            >
              {t('verification.resend')}
              <span className={styles.timer}>{timer ? `00:${transformedTime}` : ''}</span>
            </TextButton>
            <div className={styles.phoneLoginButtonGroup}>
              <SolidButton
                as="button"
                type="submit"
                disabled={methods.formState.isSubmitting}
                className={styles.button}
              >
                {t('verification.enter')}
              </SolidButton>
              <TextButton
                as="button"
                type="button"
                className={clsx(styles.button)}
                onClick={setOtpStatus}
              >
                {t('verification.changeNumber')}
              </TextButton>
            </div>
          </Form.Footer>
        </Form>
      </FormProvider>
      <SessionsModal
        sessions={sessions}
        onClose={() => setSessions([])}
        onSessionDelete={handleDeleteSession}
      />
    </Fragment>
  );
};
