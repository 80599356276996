'use client';

import { ReactNode } from 'react';

import { useTranslations } from 'next-intl';

import AlertSVG from '@/icons/AlertSVG';
import { ChildrenProps } from '@/types/common.types';

import styles from '@/components/Alert/Alert.module.scss';
import { Modal, ModalProps } from '@/components/Modal';

// =================================================================

export interface AlertProps extends ChildrenProps, ModalProps {
  title?: string;
  footer?: ReactNode;
}

// =================================================================

export const Alert = (props: AlertProps) => {
  const t = useTranslations('common');
  const { title = t('attention'), footer, children, onClose, ...rest } = props;

  return (
    <Modal {...rest} onClose={onClose}>
      <Modal.Header
        icon={<AlertSVG width={30} height={30} className={styles.alertIcon} />}
        onClose={onClose}
      >
        {title}
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {footer && <Modal.Footer className={styles.alertFooter}>{footer}</Modal.Footer>}
    </Modal>
  );
};
