'use client';

import { useCallback } from 'react';

import { useTranslations } from 'next-intl';
import { useSearchParams } from 'next/navigation';

import { useRouter } from '@/navigation';

import { Modal, ModalProps } from '@/components/Modal';

import { AuthVerification } from '@/contexts/VerificationContext/VerificationModal/AuthVerification';
import { SubscriptionVerification } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification';
import styles from '@/contexts/VerificationContext/VerificationModal/VerificationModal.module.scss';

// =================================================================

interface VerificationModalProps extends Omit<ModalProps, 'canOutsideClickClose' | 'children'> {
  isAuthVerification?: boolean;
  isSubscriptionVerification?: boolean;
}

// =================================================================

export const VerificationModal = (props: VerificationModalProps) => {
  const { isAuthVerification, isSubscriptionVerification, onClose, ...rest } = props;

  const t = useTranslations('verification');

  const router = useRouter();
  const searchParams = useSearchParams();

  const handleCloseModal = useCallback(() => {
    onClose();

    const verificationQuery = searchParams.get('verification');

    if (verificationQuery && JSON.parse(verificationQuery) === true) {
      const currentSearchParams = new URLSearchParams(Array.from(searchParams.entries()));
      currentSearchParams.delete('verification');
      const query = currentSearchParams.toString();

      router.replace(`/?${query}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose]);

  return (
    <Modal canOutsideClickClose={false} onClose={handleCloseModal} {...rest}>
      <Modal.Header onClose={handleCloseModal}>
        <h2>
          {isAuthVerification && t('authorization')}
          {isSubscriptionVerification && t('subscription')}
        </h2>
      </Modal.Header>
      <Modal.Body className={styles.verificationModalBody}>
        {isAuthVerification && <AuthVerification />}
        {isSubscriptionVerification && <SubscriptionVerification />}
      </Modal.Body>
    </Modal>
  );
};
