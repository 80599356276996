'use client';

import { useStore } from '@/contexts/StoreContext';
import styles from '@/contexts/VerificationContext/VerificationModal/AuthVerification/AuthVerification.module.scss';
import { LoginWithPhone } from '@/contexts/VerificationContext/VerificationModal/AuthVerification/LoginWithPhone';
import { LoginWithUsername } from '@/contexts/VerificationContext/VerificationModal/AuthVerification/LoginWithUsername';

export const AuthVerification = () => {
  const { isLoginWithPhone } = useStore(store => store.verificationStore);

  return (
    <div className={styles.authVerificationWrapper}>
      {isLoginWithPhone ? <LoginWithPhone /> : <LoginWithUsername />}
    </div>
  );
};
