'use client';

import { Fragment, useState } from 'react';

import { useTranslations } from 'next-intl';

import { User } from '@/app/actions/auth/types';
import TrashbinSVG from '@/icons/TrashbinSVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { UserApi } from '@/api/domains/user-api';

import { CardAlert } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/CardAlert';
import styles from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/UserCard.module.scss';

// =================================================================

export const UserCard = (props: Pick<User, 'card'>) => {
  const { card } = props;

  const t = useTranslations('page.userProfile');

  const { userCredentials, setUserCredentials } = useVerification();

  const [deleteModal, setDeleteModal] = useState(false);
  const [message, setMessage] = useState('');
  const [isPending, setIsPending] = useState(false);

  const handleOpenDeleteModal = () => {
    setDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleDelete = async () => {
    setIsPending(true);
    setMessage('');
    try {
      await UserApi.deleteCard(card?.id as number);

      const userData = { ...userCredentials, card: null } as User;
      setUserCredentials(userData);
      setIsPending(false);
      setDeleteModal(false);
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      const { message } = error.response;
      setMessage(message);
      setIsPending(false);
    }
  };

  const cardExpData = `${card?.card_exp?.slice(2, 4)}/${card?.card_exp?.slice(0, 2)}`;
  return (
    <Fragment>
      <div className={styles.bankCard}>
        <div>
          <p className={styles.userInfo}>{card?.owner_name}</p>
          <p className={styles.number}>
            {card?.card_number}
            {` ${cardExpData}`}
          </p>
        </div>
        <button className={styles.hasCardDeleteButton} onClick={handleOpenDeleteModal}>
          <TrashbinSVG width={18} height={23} />
        </button>
      </div>

      <CardAlert
        size="lg"
        isOpen={deleteModal}
        onClick={handleDelete}
        onClose={handleCloseDeleteModal}
        isPending={isPending}
        cancelText="Отмена"
        confirmationText="Да"
      >
        <div className={styles.modalContainer}>
          <div className={styles.modalTitle}>{t('deleteWarningMessage')}</div>
          {message && <p className={styles.error}>{message}</p>}
        </div>
      </CardAlert>
    </Fragment>
  );
};
