'use client';

import { Fragment, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslations } from 'next-intl';
import * as yup from 'yup';

import { AddCardForm } from '@/app/actions/bank-card/types';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { UserApi } from '@/api/domains/user-api';

import { SolidButton } from '@/components/Buttons/SolidButton';
import { Form, FormAlertType } from '@/components/Form';
import { Modal } from '@/components/Modal';

import styles from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/HasNotCard.module.scss';
import { RenewForm } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/RenewForm';
import { WriteOffPrice } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/WriteOffPrice';
import { useBankCard } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/contexts';
import { SubscriptionInfoCard } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/SubscriptionInfoCard';

// =================================================================

export const HasNotCard = () => {
  const t = useTranslations();

  const [status, setStatus] = useState<FormAlertType['status'] | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const { setHasOTP, setData, setResponseMessage } = useBankCard();
  const { subscription } = useVerification();

  const schemaBankCard = useMemo(
    () =>
      yup.object().shape({
        card_number: yup
          .string()
          .required(t('formWarningMassage.enterCardDetails'))
          .matches(/^\d{4} \d{4} \d{4} \d{4}$/, t('formWarningMassage.invalidCardNumberFormat')),
        card_exp: yup
          .string()
          .required(t('formWarningMassage.enterCardDetails'))
          .test('expireDateFormat', t('formWarningMassage.invalidTermFormat'), value => {
            if (!value) return false; // If value is null or empty, it's invalid
            const parts = value.split('/');
            if (parts.length !== 2) return false; // Date should have two parts: month and year
            const month = parseInt(parts[0], 10);
            const year = parseInt(parts[1], 10);
            // Validate month and year
            return (
              month >= 1 &&
              month <= 12 &&
              year >= 0 &&
              year <= 99 &&
              /^\d{2}$/.test(parts[1].trim())
            );
          }),
      }),
    [t],
  );

  const methods = useForm<AddCardForm>({
    defaultValues: {
      card_number: '',
      card_exp: '',
    },
    resolver: yupResolver(schemaBankCard),
    mode: 'onChange',
  });

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const onSubmit = async (data: AddCardForm) => {
    const transformedBankCardNumber = data.card_number.split(' ').join('');

    const transformedExpireDate = data.card_exp.split('/').reverse().join('').trim();
    try {
      const { message } = await UserApi.addBankCard({
        card_exp: transformedExpireDate,
        card_number: transformedBankCardNumber,
      });

      setData({
        card_number: transformedBankCardNumber,
        card_exp: transformedExpireDate,
      });
      setHasOTP(true);
      setResponseMessage(message);
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      const { message } = error.response;
      setStatus({ type: 'error', message });
    }
  };

  return (
    <Fragment>
      <FormProvider {...methods}>
        <Form
          onSubmit={methods.handleSubmit(onSubmit)}
          isSubmitting={methods.formState.isSubmitting}
          autoComplete="off"
          className={styles.form}
        >
          <div className={styles.modalHeader}>
            {status && <Form.Alert status={status} />}
            <h3>{t('verification.enterCardDetails')}</h3>
          </div>
          <Form.Body className={styles.modalBody}>
            <div className={styles.inputGroup}>
              <Form.NumberInput
                name="card_number"
                placeholder={t('verification.cardNumber')}
                format="#### #### #### ####"
                mask="_"
                className={styles.input}
                errorClassName={styles.errorMessage}
              />
              <Form.NumberInput
                name="card_exp"
                placeholder={t('verification.expirationDate')}
                format="## / ##"
                mask="_"
                className={styles.input}
                errorClassName={styles.errorMessage}
              />
            </div>
          </Form.Body>
          {subscription?.notes && <SubscriptionInfoCard notes={subscription.notes} />}
          <WriteOffPrice />
          <RenewForm
            isHasRenew={subscription?.forcePayWithCard}
            disabled={Boolean(subscription?.notes)}
          />

          <Form.Footer className={styles.buttonsWrapper}>
            <SolidButton as="button" type="submit" disabled={methods.formState.isSubmitting}>
              {t('verification.payAndPurchase')}
            </SolidButton>
          </Form.Footer>
        </Form>
      </FormProvider>

      <Modal isOpen={openModal} size="sm" onClose={handleCloseModal}>
        <Modal.Header>{t('verification.success')}</Modal.Header>
        <div className={styles.modalContainer}>
          <Modal.Body className={styles.modalTitle}>
            {t('verification.successPaymentResponse')}
          </Modal.Body>
          <Modal.Footer className={styles.modalButtons}>
            <SolidButton as="button" className={styles.modalButton} onClick={handleCloseModal}>
              {t('verification.fine')}
            </SolidButton>
          </Modal.Footer>
        </div>
      </Modal>
    </Fragment>
  );
};
