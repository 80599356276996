import type { SVGProps } from '@/types/common.types';

const TrashbinSVG = (props: SVGProps) => {
  return (
    <svg
      viewBox="0 0 18 23"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9.00047 8.02954C8.71379 8.02954 8.48145 8.26189 8.48145 8.54857V18.3582C8.48145 18.6446 8.71379 18.8772 9.00047 18.8772C9.28716 18.8772 9.5195 18.6446 9.5195 18.3582V8.54857C9.5195 8.26189 9.28716 8.02954 9.00047 8.02954Z" />
      <path d="M12.062 8.02954C11.7754 8.02954 11.543 8.26189 11.543 8.54857V18.3582C11.543 18.6446 11.7754 18.8772 12.062 18.8772C12.3487 18.8772 12.5811 18.6446 12.5811 18.3582V8.54857C12.5811 8.26189 12.3487 8.02954 12.062 8.02954Z" />
      <path d="M5.93797 8.02954C5.65129 8.02954 5.41895 8.26189 5.41895 8.54857V18.3582C5.41895 18.6446 5.65129 18.8772 5.93797 18.8772C6.22465 18.8772 6.45701 18.6446 6.45701 18.3582V8.54857C6.45701 8.26189 6.22465 8.02954 5.93797 8.02954Z" />
      <path d="M1.47412 6.59799V19.3857C1.47412 20.1415 1.75127 20.8513 2.23543 21.3606C2.71735 21.8713 3.38803 22.1613 4.08993 22.1625H13.9101C14.6122 22.1613 15.2829 21.8713 15.7646 21.3606C16.2487 20.8513 16.5259 20.1415 16.5259 19.3857V6.59799C17.4883 6.34253 18.112 5.41274 17.9832 4.42517C17.8543 3.43781 17.0131 2.69921 16.0172 2.699H13.3598V2.05022C13.3629 1.50464 13.1471 0.980744 12.7609 0.595326C12.3747 0.210112 11.85 -0.00439219 11.3044 6.81958e-05H6.6956C6.15002 -0.00439219 5.62532 0.210112 5.23909 0.595326C4.85286 0.980744 4.63714 1.50464 4.64018 2.05022V2.699H1.98281C0.986924 2.69921 0.145736 3.43781 0.0167908 4.42517C-0.111952 5.41274 0.51169 6.34253 1.47412 6.59799ZM13.9101 21.1244H4.08993C3.20252 21.1244 2.51217 20.3621 2.51217 19.3857V6.6436H15.4878V19.3857C15.4878 20.3621 14.7975 21.1244 13.9101 21.1244ZM5.67823 2.05022C5.67479 1.77996 5.78102 1.51984 5.97282 1.32906C6.16441 1.13828 6.42514 1.03346 6.6956 1.03812H11.3044C11.5749 1.03346 11.8356 1.13828 12.0272 1.32906C12.219 1.51964 12.3252 1.77996 12.3218 2.05022V2.699H5.67823V2.05022ZM1.98281 3.73706H16.0172C16.5332 3.73706 16.9514 4.15532 16.9514 4.6713C16.9514 5.18729 16.5332 5.60555 16.0172 5.60555H1.98281C1.46682 5.60555 1.04856 5.18729 1.04856 4.6713C1.04856 4.15532 1.46682 3.73706 1.98281 3.73706Z" />
    </svg>
  );
};

export default TrashbinSVG;
