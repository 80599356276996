import type { SVGProps } from '@/types/common.types';

const DevicePCSVG = (props: SVGProps) => {
  return (
    <svg
      viewBox="0 0 32 28"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.0909 0H2.90909C1.30909 0 0 1.26 0 2.8V19.6C0 21.14 1.30909 22.4 2.90909 22.4H13.0909V25.2H10.1818V28H21.8182V25.2H18.9091V22.4H29.0909C30.6909 22.4 32 21.14 32 19.6V2.8C32 1.26 30.6909 0 29.0909 0ZM2.90909 2.8H29.0909V19.6H2.90909V2.8Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default DevicePCSVG;
