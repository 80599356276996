'use client';

import { useState } from 'react';

import { useTranslations } from 'next-intl';

import { User } from '@/app/actions/auth/types';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { UserApi } from '@/api/domains/user-api';

import { SolidButton } from '@/components/Buttons/SolidButton';
import { Modal } from '@/components/Modal';

import { CardAlert } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/CardAlert';
import styles from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/HasRequiredBalance.module.scss';
import { RenewForm } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/RenewForm';
import { WarningSubscription } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/WarningSubscription';
import { WriteOffPrice } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/WriteOffPrice';
import { SubscriptionInfoCard } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/SubscriptionInfoCard';

// =================================================================

export const HasRequiredBalance = () => {
  const t = useTranslations('verification');

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState('');
  const [isPending, setIsPending] = useState(false);

  const {
    subscription,
    userCredentials,
    subscriptionCallbackOnSuccess,
    isRenew,
    setUserCredentials,
  } = useVerification();

  const handlePayment = async () => {
    setIsPending(true);
    setMessage('');

    try {
      await UserApi.purchaseSubscription(Number(subscription?.id), isRenew);
      const amount = Number(subscription?.price);
      const userData = {
        ...userCredentials,
        balance: userCredentials!.balance - amount >= 0 ? userCredentials!.balance - amount : 0,
      } as User;

      setUserCredentials(userData);
      setOpenConfirmation(false);
      setIsPending(false);
      setOpenModal(true);
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      setMessage(error.response.data.message);
      setIsPending(false);
    }
  };

  const handleConfirmationModalOpen = () => {
    setOpenConfirmation(true);
  };

  const handleConfirmationModalClose = () => {
    setMessage('');
    setOpenConfirmation(false);
  };

  const handleCloseModal = () => {
    subscriptionCallbackOnSuccess();
    setOpenModal(false);
  };

  return (
    <div className={styles.hasRequiredBalanceWrapper}>
      {subscription?.notes && <SubscriptionInfoCard notes={subscription.notes} />}
      <WriteOffPrice />
      <RenewForm
        isHasRenew={subscription?.forcePayWithCard}
        disabled={Boolean(subscription?.notes)}
      />

      <SolidButton as="button" onClick={handleConfirmationModalOpen}>
        {t('payAndPurchase')}
      </SolidButton>

      <Modal isOpen={openModal} size="sm" onClose={handleCloseModal}>
        <Modal.Header>{t('success')}</Modal.Header>
        <div className={styles.modalContainer}>
          <Modal.Body className={styles.modalTitle}>{t('successPaymentResponse')}</Modal.Body>
          <Modal.Footer className={styles.modalButtons}>
            <SolidButton as="button" onClick={handleCloseModal}>
              {t('fine')}
            </SolidButton>
          </Modal.Footer>
        </div>
      </Modal>

      <CardAlert
        size="lg"
        isOpen={openConfirmation}
        onClick={handlePayment}
        onClose={handleConfirmationModalClose}
        isPending={isPending}
        cancelText={t('no')}
        confirmationText={t('yes')}
      >
        {subscription && <WarningSubscription subscription={subscription} message={message} />}
      </CardAlert>
    </div>
  );
};
