import type { CreateSlice } from '@/zustand-store/store.types';

export type HeaderStore = {
  isSearchBarVisible: boolean;
  isSidebarVisible: boolean;
  toggleSearchBar: () => void;
  showSidebar: () => void;
  hideSidebar: () => void;
};

export const createHeaderSlice: CreateSlice<HeaderStore> = set => ({
  isSearchBarVisible: false,
  isSidebarVisible: false,
  toggleSearchBar: () =>
    set(state => ({ ...state, isSearchBarVisible: !state.isSearchBarVisible })),
  showSidebar: () => set(state => ({ ...state, isSidebarVisible: true })),
  hideSidebar: () => set(state => ({ ...state, isSidebarVisible: false })),
});
