import { Alert, AlertProps } from '@/components/Alert';
import { SolidButton } from '@/components/Buttons/SolidButton';

import styles from '@/views/UserProfileViews/AddCardView/CardAlert.module.scss';

// =================================================================

interface CardAlertProps extends AlertProps {
  isPending: boolean;
  cancelText: string;
  confirmationText: string;
  onClick: () => void;
}

// =================================================================

export const CardAlert = (props: CardAlertProps) => {
  const {
    isOpen,
    onClose,
    onClick,
    children,
    cancelText,
    confirmationText,
    isPending = false,
  } = props;

  return (
    <Alert
      isOpen={isOpen}
      size="sm"
      footer={
        <div className={styles.modalButtons}>
          <SolidButton as="button" variant="dimmed" onClick={onClose}>
            {cancelText}
          </SolidButton>
          <SolidButton as="button" onClick={onClick} disabled={isPending}>
            {confirmationText}
          </SolidButton>
        </div>
      }
      onClose={onClose}
    >
      {children}
    </Alert>
  );
};
