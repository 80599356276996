'use client';

import { useState, Fragment, useMemo } from 'react';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslations } from 'next-intl';
import * as yup from 'yup';

import { signIn } from '@/app/actions/auth';
import type {
  SessionList,
  SessionsReachedResponse,
  SignInResponse,
} from '@/app/actions/auth/types';
import UserLoginSVG from '@/icons/UserLoginSVG';
import UserPasswordSVG from '@/icons/UserPasswordSVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { customLocalStorage } from '@/helpers/storage.helpers';

import {
  HTTP_CODE_REACHED_SESSIONS_LIMIT,
  HTTP_CODE_SUCCESS,
} from '@/constants/http-code.constants';
import { USER_ACCESS_TOKEN } from '@/constants/user-credentials.constants';

import { TextButton } from '@/components/Buttons';
import { SolidButton } from '@/components/Buttons/SolidButton';
import { Form, FormAlertType } from '@/components/Form';
import { SessionsModal } from '@/components/SessionsModal';

import styles from '@/contexts/VerificationContext/VerificationModal/AuthVerification/AuthVerification.module.scss';
import { SocialLoginPanel } from '@/contexts/VerificationContext/VerificationModal/AuthVerification/SocialLoginPanel';

// =================================================================

type LoginFormType = {
  username: string;
  password: string;
  shouldRemember: boolean;
};

// =================================================================

export const LoginWithUsername = () => {
  const { loginWithPhone, authCallbackOnSuccess, setAccessToken, setUserCredentials } =
    useVerification();
  const t = useTranslations();

  const [status, setStatus] = useState<FormAlertType['status'] | null>(null);
  const [sessions, setSessions] = useState<SessionList[]>([]);
  const [formData, setFormData] = useState<LoginFormType>({
    username: '',
    password: '',
    shouldRemember: true,
  });

  const schema = useMemo(
    () =>
      yup.object({
        username: yup.string().trim().required(t('formWarningMassage.requiredField')),
        password: yup.string().required(t('formWarningMassage.requiredField')),
        shouldRemember: yup.boolean().required(),
      }),
    [t],
  );

  const methods = useForm<LoginFormType>({
    defaultValues: formData,
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<LoginFormType> = async data => {
    setFormData(data);

    const response = await signIn(data);

    if (response.code === HTTP_CODE_SUCCESS) {
      const { cineramaToken, me } = response.data as SignInResponse;
      setAccessToken(cineramaToken);
      setUserCredentials(me);
      authCallbackOnSuccess();
    } else if (response.code === HTTP_CODE_REACHED_SESSIONS_LIMIT) {
      const { sessionList } = response.data as SessionsReachedResponse;
      setSessions(sessionList);
      setStatus({
        type: 'error',
        message: response.message,
      });
    } else {
      setStatus({
        type: 'error',
        message: response.message,
      });
    }
  };

  const handleDeleteSession = async () => {
    const response = await signIn(formData);

    if (response.code === HTTP_CODE_SUCCESS) {
      const { cineramaToken, me } = response.data as SignInResponse;
      setAccessToken(cineramaToken);
      setUserCredentials(me);

      authCallbackOnSuccess();
    }
  };

  return (
    <Fragment>
      <FormProvider {...methods}>
        <Form
          isSubmitting={methods.formState.isSubmitting}
          onSubmit={methods.handleSubmit(onSubmit)}
          className={styles.authForm}
        >
          {status && <Form.Alert status={status} className={styles.alert} />}
          <Form.Body className={styles.authFormBody}>
            <Form.Input
              name="username"
              label={t('verification.login')}
              icon={UserLoginSVG}
              errorClassName={styles.errorMessage}
              autoFocus
            />
            <Form.Input
              type="password"
              name="password"
              label={t('verification.password')}
              icon={UserPasswordSVG}
              passwordView
              errorClassName={styles.errorMessage}
            />
            <Form.Panel align="flexBetween">
              <Form.Checkbox
                name="shouldRemember"
                label={<Fragment>{t('verification.rememberMe')}</Fragment>}
              />
            </Form.Panel>
          </Form.Body>
          <div className={styles.lineRow} />
          <Form.Footer className={styles.formFooter}>
            <div className={styles.phoneLoginButtonGroup}>
              <TextButton
                as="button"
                type="button"
                className={styles.button}
                onClick={loginWithPhone}
              >
                {t('verification.signInUsingYourPhone')}
              </TextButton>
              <SolidButton
                as="button"
                type="submit"
                disabled={methods.formState.isSubmitting}
                className={styles.button}
              >
                {t('verification.toComeIn')}
              </SolidButton>
            </div>
            <SocialLoginPanel />
          </Form.Footer>
        </Form>
      </FormProvider>
      <SessionsModal
        sessions={sessions}
        onClose={() => setSessions([])}
        onSessionDelete={handleDeleteSession}
      />
    </Fragment>
  );
};
