'use client';

import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { useTranslations } from 'next-intl';
import * as yup from 'yup';

import { User } from '@/app/actions/auth/types';
import { ConfirmationForm } from '@/app/actions/bank-card/types';
import KeySVG from '@/icons/KeySVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { UserApi } from '@/api/domains/user-api';

import { TextButton } from '@/components/Buttons';
import { SolidButton } from '@/components/Buttons/SolidButton';
import { Form, FormAlertType } from '@/components/Form';
import { Modal } from '@/components/Modal';

import styles from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/CardConfirmation.module.scss';
import { useBankCard } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/contexts/BankCardProvider';

// =================================================================

export const CardConfirmation = () => {
  const t = useTranslations();

  const { userCredentials, setUserCredentials } = useVerification();

  const [status, setStatus] = useState<FormAlertType['status'] | null>(null);
  const [timer, setTimer] = useState<number>(59);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [responseMessageAfterPayment, setResponseMessageAfterPayment] = useState('');

  const { responseMessage, data, purchaseSubscriptionWithCard, setHasOTP } = useBankCard();
  const { subscription, subscriptionCallbackOnSuccess } = useVerification();

  const schema = useMemo(
    () =>
      yup
        .object({
          code: yup
            .string()
            .trim()
            .matches(/[0-9]{5}/, t('formWarningMassage.invalidCode'))
            .required(t('formWarningMassage.requiredField')),
        })
        .required(),
    [t],
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer) {
        setTimer(timer => timer - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  const transformedTime = ('00' + timer).slice(-2);

  const resendCode = async () => {
    if (!data) return;
    try {
      setTimer(59);
      await UserApi.addBankCard(data);
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      const { message } = error.response;
      setStatus({ type: 'error', message });
    }
  };

  const methods = useForm<ConfirmationForm>({
    defaultValues: {
      code: '',
    },
    resolver: yupResolver(schema),
  });

  const handleCloseModal = async () => {
    subscriptionCallbackOnSuccess();
    setHasOTP(false);
    setOpenModal(false);
  };

  const onSubmit = async (formData: ConfirmationForm) => {
    try {
      const response = await UserApi.cardConfrimOTP(formData.code);
      const userData = { ...userCredentials, card: response.data } as User;
      setUserCredentials(userData);

      if (isEmpty(subscription)) {
        setHasOTP(false);
      } else {
        setHasOTP(true);
        const responseMessage = await purchaseSubscriptionWithCard(subscription, setOpenModal);
        setResponseMessageAfterPayment(responseMessage);
      }
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      const { message } = error.response;
      setStatus({ type: 'error', message });
    }
  };

  useEffect(() => {
    methods.setError('code', {
      message: status?.message,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <div className={styles.confirmationContainer}>
      <FormProvider {...methods}>
        <Form
          onSubmit={methods.handleSubmit(onSubmit)}
          isSubmitting={methods.formState.isSubmitting}
          autoComplete="off"
          className={styles.formWrapper}
        >
          <div className={styles.formHeader}>
            <h3>{t('verification.confirmationCode')}</h3>
            <p className={styles.subTitle}>{responseMessage}</p>
          </div>
          <Form.Body>
            <Form.NumberInput
              format="######"
              mask="_"
              name="code"
              placeholder={t('verification.placeholderCode')}
              icon={KeySVG}
              errorClassName={styles.errorMessage}
            />
          </Form.Body>
          <div className={styles.formFooter}>
            <SolidButton as="button" type="submit" disabled={methods.formState.isSubmitting}>
              {t('verification.sendCode')}
            </SolidButton>
            <TextButton as="button" type="button" disabled={Boolean(timer)} onClick={resendCode}>
              {timer ? `00:${transformedTime}` : ''} {t('verification.resend')}
            </TextButton>
          </div>
        </Form>

        <Modal isOpen={openModal} size="sm" onClose={handleCloseModal}>
          <Modal.Header>{t('verification.success')}</Modal.Header>
          <div className={styles.modalContainer}>
            <Modal.Body className={styles.modalTitle}>
              {responseMessageAfterPayment || t('verification.successPaymentResponse')}
            </Modal.Body>
            <Modal.Footer className={styles.modalButtons}>
              <SolidButton as="button" className={styles.modalButton} onClick={handleCloseModal}>
                {t('verification.fine')}
              </SolidButton>
            </Modal.Footer>
          </div>
        </Modal>
      </FormProvider>
    </div>
  );
};
